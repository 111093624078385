import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import uuidV4 from 'uuid/v4';

import { fetchNotifications, sawNotifications } from '../../actions/notificationActions';
import { refreshLastSeenNotifyDate } from '../../actions/userAgendaSettingsActions';

class SideBarMenuItem extends Component {
  getCount = (path) => {
    let { notificationCounts } = this.props;
    const count = notificationCounts[path] || 0;

    return count;
  };

  render() {
    let { path, testId } = this.props;
    let count = 0;
    if (Array.isArray(path)) {
      path.map(p => {
        count += this.getCount(p);
        return p;
      });
    } else {
      count = this.getCount(path);
    }

    let wrapperClass = classNames({
      "notification-event": true,
      "notification-event-visible": count !== 0,
    });

    const innerChildren = (
      <span key={uuidV4()}
        className={wrapperClass}
        data-testid={count !== 0 ? `${testId}-event-count` : null}
      >
        {count}
      </span>
    );
    const childrenClass = this.props.children.props.className ?? '';
    const newChildrenClass = `${count !== 0 ? 'has-notification' : ''} ${childrenClass}`;

    return React.cloneElement(this.props.children, {
      ...this.props.children.props,
      className: newChildrenClass,
      onClick: () => {
        if (!Array.isArray(path)) {
          this.props.notificationSaw(path);
          this.props.refreshLastSeenNotifyDate(path);
        }

        if (this.props.children.props.onClick) {
          this.props.children.props.onClick();
        }
      }
    }, [...this.props.children.props.children, innerChildren]);
  }
}

SideBarMenuItem.propTypes = {
  path: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    notificationCounts: state.notification.counts,
  };
};


const mapDispatchToProps = ({
  fetchData: fetchNotifications,
  notificationSaw: sawNotifications,
  refreshLastSeenNotifyDate,
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBarMenuItem);
