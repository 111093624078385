import PropTypes from 'prop-types';

import NavLink from './NavLink';
import NavGroup from './NavGroup';
import Translation from './Translation';
import SideBarMenuItem from './SideBarMenuItem';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import { PERMISSION_TYPES } from '../../common/types/permissionTypes';
import { NOTIFICATION_TYPES } from '../../common/types/notificationTypes';
import { useTranslation } from 'react-i18next';

const SideBarMenu = ({ path, className, permissions }) => {
  const { t } = useTranslation();
  const renderNavLink = ({ link, icon, tKey, testId }) => (
    <NavLink to={link} path={path} testId={testId}><span className={`icon icon-${icon}`} />
      <span className="sidebar-item-label">
        <Translation tKey={tKey} />
      </span>
    </NavLink>
  );

  const renderSideBarMenuItem = (itemPath, { link, icon, tKey, testId }) => (
    <SideBarMenuItem path={itemPath} testId={testId}>
      {renderNavLink({ link, icon, tKey, testId })}
    </SideBarMenuItem>
  );

  const renderManagePartners = () => {
    if (!permissions.includes(PERMISSION_TYPES.MANAGE_PARTNERS)) {
      return;
    }

    return (
      <>
        <div className="sidebar-label"><Translation tKey="PartnersManagement" /></div>
        <ul>
          {renderSideBarMenuItem(NOTIFICATION_TYPES.partnership, {
            link: NAVIGATION_PATHS.PARTNERS,
            icon: 'male',
            tKey: 'Partners',
            testId: 'sidebar-item-partners'
          })}
          {renderSideBarMenuItem(NOTIFICATION_TYPES.partnerInvitations, {
            link: NAVIGATION_PATHS.PARTNER_INVITATIONS,
            icon: 'mail-open',
            tKey: 'Invitations',
            testId: 'sidebar-item-invitations'
          })}
        </ul>
      </>
    );
  };

  const renderManageDevices = () => {
    if (!permissions.includes(PERMISSION_TYPES.MANAGE_DEVICE)) {
      return;
    }

    return (
      <>
        <div className="sidebar-label"><Translation tKey="DeviceManagement" /></div>
        <ul>
          {renderSideBarMenuItem(NOTIFICATION_TYPES.partnership, {
            link: NAVIGATION_PATHS.DEVICES,
            icon: 'mobile',
            tKey: 'MobileApplications',
            testId: 'sidebar-item-mobile-app'
          })}
        </ul>
      </>
    );
  };

  const renderArchive = () => {
    if (!permissions.includes(PERMISSION_TYPES.ARCHIVE)) {
      return;
    }

    return (
      <>
        <div className="sidebar-label"><Translation tKey="Archive.Title" /></div>
        <ul>
          {renderNavLink({
            link: NAVIGATION_PATHS.ARCHIVE,
            icon: 'cabinet-filing',
            tKey: 'Archive.Title',
            testId: 'sidebar-item-archive'
          })}
          {permissions.includes(PERMISSION_TYPES.ARCHIVE_PACKAGES) &&
            renderNavLink({
              link: NAVIGATION_PATHS.ARCHIVE_PACKAGES,
              icon: 'box-alt',
              tKey: 'ArchivePackages.Title',
              testId: 'sidebar-item-archive-packages'
            })}
        </ul>
      </>
    );
  };

  const renderManageUsers = () => {
    if (!permissions.includes(PERMISSION_TYPES.MANAGE_USERS)) {
      return;
    }

    return (<>
      <div className="sidebar-label"><Translation tKey="ManageUsers" /></div>
      <ul>
        {renderSideBarMenuItem(NOTIFICATION_TYPES.users, {
          link: NAVIGATION_PATHS.USERS,
          icon: 'users',
          tKey: 'Users',
          testId: 'sidebar-item-users'
        })}
        {renderSideBarMenuItem(NOTIFICATION_TYPES.userInvitations, {
          link: NAVIGATION_PATHS.USER_INVITATIONS,
          icon: 'mail-open',
          tKey: 'Invitations',
          testId: 'sidebar-item-user-invitations'
        })}
        {renderNavLink({
          link: NAVIGATION_PATHS.USER_PERMISSIONS,
          icon: "lock",
          tKey: "Permissions",
          testId: 'sidebar-item-permissions'
        })}
      </ul>
    </>);
  };

  const renderInvoiceIncoming = () => {
    if (!permissions.includes(PERMISSION_TYPES.INVOICE_INCOMING)) {
      return;
    }

    return (
      renderSideBarMenuItem(NOTIFICATION_TYPES.invoices_incoming, {
        link: NAVIGATION_PATHS.INVOICES_INCOMING,
        icon: 'inbox-in',
        tKey: 'Incoming',
      }));
  };

  const renderInvoiceOutgoing = () => {
    if (!permissions.includes(PERMISSION_TYPES.INVOICE_OUTGOING)) {
      return;
    }

    return (
      renderSideBarMenuItem(NOTIFICATION_TYPES.invoices_outgoing, {
        link: NAVIGATION_PATHS.INVOICES_OUTGOING,
        icon: 'inbox-out',
        tKey: 'Outgoing',
      }));
  };

  const renderTransfers = () => {
    if (!permissions.includes(PERMISSION_TYPES.INVOICE_INCOMING) && !permissions.includes(PERMISSION_TYPES.INVOICE_OUTGOING)) {
      return;
    }

    return (<>
      <div className="sidebar-label"><Translation tKey="Transfers" /></div>
      <ul>
        <NavGroup
          to={NAVIGATION_PATHS.INVOICES}
          path={path}
          title={<Translation tKey="Invoices" />}
          icon="icon icon-file"
          notificationPath={[NOTIFICATION_TYPES.invoices_outgoing, NOTIFICATION_TYPES.invoices_incoming]}>
          {renderInvoiceOutgoing()}
          {renderInvoiceIncoming()}
        </NavGroup>
      </ul>
    </>);
  };

  const renderWorkflowQueue = () => {
    if (!permissions.includes(PERMISSION_TYPES.WORKFLOW_QUEUE)) {
      return;
    }

    return (
      renderSideBarMenuItem(NOTIFICATION_TYPES.workflow_inputQueue, {
        link: NAVIGATION_PATHS.WORKFLOW_QUEUE,
        icon: 'circle-empty',
        tKey: 'Workflow.Queue.Menu',
        testId: 'sidebar-item-workflow-queue'
      })
    );
  };

  const renderWorkflowAgreementProcess = () => {
    if (!permissions.includes(PERMISSION_TYPES.WORKFLOW_AGREEMENT_PROCESS)) {
      return;
    }

    return (
      renderSideBarMenuItem(NOTIFICATION_TYPES.workflow_agreementProcess, {
        link: NAVIGATION_PATHS.WORKFLOW_AGREEMENT_PROCESS,
        icon: 'handshake',
        tKey: 'Workflow.AgreementProcess.Menu',
        testId: 'sidebar-item-agreement-process'
      })
    );
  };

  const renderWorkflowHistory = () => {
    if (!permissions.includes(PERMISSION_TYPES.WORKFLOW_ARCHIVE) && !permissions.includes(PERMISSION_TYPES.WORKFLOW_USER_HISTORY)) {
      return;
    }

    return (
      renderNavLink({
        link: NAVIGATION_PATHS.WORKFLOW_HISTORY,
        icon: 'th-list',
        tKey: 'Workflow.History.ShortTitle',
        testId: 'sidebar-item-workflow-history'
      })
    );
  };

  const renderWorkflowStatistics = () => {
    if (!permissions.includes(PERMISSION_TYPES.WORKFLOW_STATISTICS)) {
      return;
    }

    return (
      renderNavLink({
        link: NAVIGATION_PATHS.WORKFLOW_STATISTICS,
        icon: 'chart-bar',
        tKey: 'Statistics',
        testId: 'sidebar-item-statistics'
      })
    );
  };

  const renderWorkflow = () => {
    if (!permissions.includes(PERMISSION_TYPES.WORKFLOW_ARCHIVE) &&
      !permissions.includes(PERMISSION_TYPES.WORKFLOW_AGREEMENT_PROCESS) &&
      !permissions.includes(PERMISSION_TYPES.WORKFLOW_QUEUE) &&
      !permissions.includes(PERMISSION_TYPES.WORKFLOW_STATISTICS)) {
      return;
    }

    return (
      <>
        <div className="sidebar-label"><Translation tKey="Workflow.Menu" /></div>
        <ul>
          {renderWorkflowQueue()}
          {renderWorkflowAgreementProcess()}
          {renderWorkflowStatistics()}
          {renderWorkflowHistory()}
        </ul>
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div className="sidebar-label"><Translation tKey="Content" /></div>
        <ul>
          {renderNavLink({
            link: NAVIGATION_PATHS.ROOT,
            icon: 'chart-pie',
            tKey: 'Dashboard',
            testId: 'sidebar-item-dashboard'
          })}
          {renderSideBarMenuItem(NOTIFICATION_TYPES.companyInvitations, {
            link: NAVIGATION_PATHS.EVENTS,
            icon: 'rss',
            tKey: 'Events',
            testId: 'sidebar-item-events'
          })}
        </ul>
      </>
    );
  };

  const renderSettings = () => {
    return (
      <>
        <div className="sidebar-label"><Translation tKey="Settings.Title" /></div>
        <ul>
          {renderNavLink({
            link: NAVIGATION_PATHS.SETTINGS,
            icon: 'cog',
            tKey: 'Settings.Title',
            testId: 'sidebar-item-settings'
          })}
        </ul>
      </>
    );
  };

  return (
    <div className={className}>
      {renderContent()}
      {renderWorkflow()}
      {renderArchive()}
      {renderManageDevices()}
      {renderManagePartners()}
      {renderManageUsers()}
      {renderSettings()}
      <div className="copyright">
        <div className="version-info"><span className="version-text">{`${t('Version')} `}</span><span>{`${process.env.REACT_APP_VERSION}`}</span></div>
        <div className="company-info">
          <span className="year">©{new Date().getFullYear()} </span>
          <span className="company-name">K-System.CZ s.r.o.</span>
        </div>
      </div>
    </div>);
};

SideBarMenu.propTypes = {
  path: PropTypes.string,
  className: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string)
};

export default SideBarMenu;
