export const WORKFLOW = {
  Selected: 'WorkflowSelected',
  Select: 'WorkflowSelect',
  Approved: 'WorkflowApproved',
  Rejected: 'WorkflowRejected',
  QueuedAtAgreementProcess: 'WorkflowQueuedAtAgreementProcess'
};

export const USER = {
  UserDeleted: 'UserDeleted'
};

export const WORKFLOW_SIMPLE = {
  WorkflowCreated: 'WorkflowCreated',
  WorkflowUpdated: 'WorkflowUpdated',
  QueuedAtInputQueue: 'WorkflowQueuedAtInputQueue',
  WorkflowAgreementProcessReceived: 'WorkflowAgreementProcessReceived',
};

export const USER_SIMPLE = {
  InvitationCreated: 'UserInvitationCreated',
  InvitationUpdated: 'UserInvitationUpdated',
  CompanyInvitationCreated: 'CompanyInvitationCreated',
  CompanyInvitationUpdated: 'CompanyInvitationUpdated',
};

export const PARTNER_SIMPLE = {
  PartnershipUpdated: 'PartnershipUpdated',
  InvitationCreated: 'PartnerInvitationCreated',
  InvitationUpdated: 'PartnerInvitationUpdated'
};

export const USER_PERMISSION_SIMPLE = {
  UserPermissionCreated: 'UserPermissionCreated',
  UserPermissionDeleted: 'UserPermissionDeleted'
};

export const INVOICE_SIMPLE = {
  OutgoingInvoiceCreated: 'OutgoingInvoiceCreated',
  OutgoingInvoiceUpdated: 'OutgoingInvoiceUpdated',
  IncomingInvoiceCreated: 'IncomingInvoiceCreated',
  IncomingInvoiceUpdated: 'IncomingInvoiceUpdated',
};

export const BILLING_SIMPLE = {
  BillingAgendaBalanceUpdated: 'BillingAgendaBalanceUpdated'
};
