import { lintel } from './common/Translation';
import { NOTIFICATION_TYPES, NOTIFICATION_LOG_TYPES, NOTIFICATION_LOG_SUB_TYPES } from '../common/types/notificationTypes';
import { SCREEN_RESOLUTION_TYPES } from '../common/types/screenResolutionTypes';
import i18n from '../i18n';
import { cultureNames } from '../common/data/localizationData';

export const validateEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateEmails = (emails) => {
  const re = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}([\s*]?[,;]\s*[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,})*$/;
  return re.test(emails);
};

export const validatePhoneNumber = (phone) => {
  let re = /^\+?[0-9]{6,15}$/;
  return re.test(phone);
};

export const validateCompanyIdentificationNumber = (cin) => {
  let reqEx = /^[0-9]{8}$/;
  if (!reqEx.test(cin)) {
    return false;
  }

  let checkSum = (11 - (cin[0] * 8 + cin[1] * 7 + cin[2] * 6 + cin[3] * 5 + cin[4] * 4 + cin[5] * 3 + cin[6] * 2) % 11) % 10;
  return (checkSum === parseInt(cin[7]));
};

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export const getBooleanText = (value, t) => {
  if (!t) {
    return '';
  }

  const result = String(value).toLowerCase() === 'true';
  return result ? t('Yes') : t('No');
};

export const getStateMessage = (checked) => checked ? "Active" : "Inactive";

export const getDataText = (value, data, t) => {
  // data: {tKey: string, value: any}[] or {text: string, value: any}[]
  const item = data.find(s => String(s.value) === String(value));
  const tKey = item?.tKey;
  const dataValue = item?.value ?? '';
  return t && tKey ? t(tKey) : dataValue;
};

export const validateFieldLength = ({ t, data, field, errors, formIsValid }) => {
  if (data[field].length <= 0) {
    let tKey = capitalize(`${field}`);
    errors[field] = lintel(t, "FieldCanNotBeEmpty", { field: t(`common:${tKey}`) });
    return false;
  }

  return formIsValid && true;
};

export const getDaysTranslationKey = (day) => {
  switch (day) {
    case 1:
      return 'OneDay';
    case 2:
    case 3:
    case 4:
      return 'TwoToFourDays';
    default:
      return 'FiveOrMoreDays';
  }
};

export const selectText = (element) => {
  let range = document.createRange();
  range.selectNodeContents(element);
  let sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
};

export const notificationSubTypeToLog = (subType) => {
  switch (subType) {
    case NOTIFICATION_LOG_SUB_TYPES.WorkflowInputQueue:
      return NOTIFICATION_TYPES.workflow_inputQueue;
    case NOTIFICATION_LOG_SUB_TYPES.Preprocessing:
      return NOTIFICATION_TYPES.workflow_inputQueue;
    case NOTIFICATION_LOG_SUB_TYPES.WorkflowOutputQueue:
      return NOTIFICATION_TYPES.workflow_outputQueue;
    case NOTIFICATION_LOG_SUB_TYPES.WorkflowAgreementProcess:
      return NOTIFICATION_TYPES.workflow_agreementProcess;
    case NOTIFICATION_LOG_SUB_TYPES.InvoiceIncoming:
      return NOTIFICATION_TYPES.invoices_incoming;
    case NOTIFICATION_LOG_SUB_TYPES.InvoiceOutgoing:
      return NOTIFICATION_TYPES.invoices_outgoing;
    case NOTIFICATION_LOG_SUB_TYPES.Partnership:
      return NOTIFICATION_TYPES.partnership;
    default:
      return undefined;
  }
};

export const notificationTypeToLog = (type, subType) => {
  switch (type) {
    case NOTIFICATION_LOG_TYPES.User:
      return NOTIFICATION_TYPES.users;
    case NOTIFICATION_LOG_TYPES.Invoice:
      return notificationSubTypeToLog(subType);
    case NOTIFICATION_LOG_TYPES.WorkflowQueueGroup:
    case NOTIFICATION_LOG_TYPES.WorkflowQueueRecord:
      return notificationSubTypeToLog(subType);
    case NOTIFICATION_LOG_TYPES.UserInvitation:
      return NOTIFICATION_TYPES.userInvitations;
    case NOTIFICATION_LOG_TYPES.CompanyInvitation:
      return NOTIFICATION_TYPES.companyInvitations;
    case NOTIFICATION_LOG_TYPES.Partner:
      return notificationSubTypeToLog(subType);
    case NOTIFICATION_LOG_TYPES.PartnerInvitation:
      let result = notificationSubTypeToLog(subType);
      if (result !== undefined) {
        return result;
      }
      return NOTIFICATION_TYPES.partnerInvitations;
    default:
      return undefined;
  }
};

export const notificationPathToTypes = (path) => {
  switch (path) {
    case NOTIFICATION_TYPES.users:
      return [NOTIFICATION_LOG_TYPES.User];
    case NOTIFICATION_TYPES.partnership:
      return [NOTIFICATION_LOG_TYPES.Partner, NOTIFICATION_LOG_SUB_TYPES.Partnership];
    case NOTIFICATION_TYPES.partnerInvitations:
      return [NOTIFICATION_LOG_TYPES.PartnerInvitation];
    case NOTIFICATION_TYPES.workflow_inputQueue:
      return [null, NOTIFICATION_LOG_SUB_TYPES.Preprocessing];
    case NOTIFICATION_TYPES.workflow_outputQueue:
      return [null, NOTIFICATION_LOG_SUB_TYPES.WorkflowOutputQueue];
    case NOTIFICATION_TYPES.workflow_agreementProcess:
      return [NOTIFICATION_LOG_TYPES.WorkflowQueueRecord, NOTIFICATION_LOG_SUB_TYPES.WorkflowAgreementProcess];
    case NOTIFICATION_TYPES.userInvitations:
      return [NOTIFICATION_LOG_TYPES.UserInvitation];
    case NOTIFICATION_TYPES.companyInvitations:
      return [NOTIFICATION_LOG_TYPES.CompanyInvitation];
    case NOTIFICATION_TYPES.invoices_incoming:
      return [NOTIFICATION_LOG_TYPES.Invoice, NOTIFICATION_LOG_SUB_TYPES.InvoiceIncoming];
    case NOTIFICATION_TYPES.invoices_outgoing:
      return [NOTIFICATION_LOG_TYPES.Invoice, NOTIFICATION_LOG_SUB_TYPES.InvoiceOutgoing];
    default:
      return undefined;
  }
};

export const getCurrentResolution = () => {
  if (window.innerWidth < 768) {
    return SCREEN_RESOLUTION_TYPES.XS;
  }
  if (window.innerWidth >= 768 && window.innerWidth <= 1023) {
    return SCREEN_RESOLUTION_TYPES.S;
  }
  if (window.innerWidth >= 1024 && window.innerWidth <= 1199) {
    return SCREEN_RESOLUTION_TYPES.M;
  }
  if (window.innerWidth >= 1200 && window.innerWidth <= 1365) {
    return SCREEN_RESOLUTION_TYPES.L;
  }
  return SCREEN_RESOLUTION_TYPES.XL;
};

export const checkLogItemByType = (item, path) => {
  const [type, subType] = notificationPathToTypes(path);
  const typeCondition = type ? item.type === type : true;
  const subTypeCondition = subType ? item.subType === subType : true;
  let extraCondition = true;
  if (path === NOTIFICATION_TYPES.userInvitations) {
    extraCondition = item.operation === 1;
  }
  return typeCondition && subTypeCondition && extraCondition;
};

export const wasInvokedBy = (event, classNames) => {
  for (let className of classNames) {
    const element = document.querySelector(className);
    if (element && element.contains(event.target)) {
      return true;
    }
  }

  return false;
};

export const wasInvokedBySomeNodeWithClass = (event, classSelector) => {
  const elements = Array.from(document.querySelectorAll(classSelector));
  return elements.some(element => element.contains(event.target));
};

export const filterTriggers = (triggers, { include = [], exclude = [] }) => {
  if (!triggers || (!include && !exclude)) {
    return triggers;
  }

  let filteredTriggers = triggers.map(t => ({ ...t, name: t.name.toLowerCase() }));
  const includeList = include.map(str => str.toLowerCase());
  const excludeList = exclude.map(str => str.toLowerCase());

  if (excludeList.length > 0) {
    filteredTriggers = filteredTriggers.filter(t => excludeList.every(triggerNamePart => !t.name.includes(triggerNamePart)));
  }
  if (includeList.length > 0) {
    filteredTriggers = filteredTriggers.filter(t => includeList.some(triggerNamePart => t.name.includes(triggerNamePart)));
  }

  return filteredTriggers;
};

export const hasSomeTrigger = (allTriggers, triggers) => {
  const filteredTriggers = filterTriggers(allTriggers, { include: triggers });
  const loading = filteredTriggers.some(t => t.total > 0);
  return loading;
};

export const invokeAsync = (func) => {
  setTimeout(() => func(), 0);
};

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

// Generate a new string that sorts between two given strings
// The idea of the algorithm:
// 1) copy the common part of given strings (from the beginning): abcde abchi  ->  abc
// 2) append the character that is halfway in the alphabet between left and right character abcde abchi  ->  abcf
// 3) empty character for first string means 'a', for second string means 'z': abc abchi  ->  abc  +  _ ~ h  ->  abcd
// 4) if right character is 'a' or 'b', only 'a' should never be appended ato the left string: a b -> aa,
//    because that would create two lexicographically consecutive strings, inbetween which no further strings could be added a aa -> ??
//    instead always append an additional character, halfway inbetween the beginning of the alphabet and the next character from the right string:
//    abc  abcah   ->  abc  +  _ ~ a  ->  abca  +  _ ~ h  ->  abcad
// 5) if next simbol is one (or more) 'z' in the first string, copy them and append middle char between the first non-z character and 'z':
//    abhz abit  ->  ab  +  h ~ i  ->  abh  +  z ~ _  ->  abhz  +  _ ~ _  ->  abhzn
export const getMiddleString = (prevString, nextString) => {
  let prevFirstDiffCode, nextFirstDiffCode, position, result;
  const codeA = 'a'.charCodeAt(0);
  const codeB = 'b'.charCodeAt(0);
  const codeZ = 'z'.charCodeAt(0);
  const prevTerminateCode = codeA - 1; // 96
  const nextTerminateCode = codeZ + 1; // 123
  for (position = 0; prevFirstDiffCode === nextFirstDiffCode; position++) { // find leftmost non-matching character
    prevFirstDiffCode = position < prevString.length ? prevString.charCodeAt(position) : prevTerminateCode;
    nextFirstDiffCode = position < nextString.length ? nextString.charCodeAt(position) : nextTerminateCode;
  }
  result = prevString.slice(0, position - 1); // copy identical part of string
  if (prevFirstDiffCode === prevTerminateCode) { // prev string equals beginning of next
    while (nextFirstDiffCode === codeA) { // next character is 'a'
      nextFirstDiffCode = position < nextString.length ? nextString.charCodeAt(position++) : nextTerminateCode; // get char from next
      result += 'a'; // insert an 'a' to match the 'a'
    }
    if (nextFirstDiffCode === codeB) { // next character is 'b'
      result += 'a'; // insert an 'a' to match the 'b'
      nextFirstDiffCode = nextTerminateCode; // set to end of alphabet
    }
  } else if (prevFirstDiffCode + 1 === nextFirstDiffCode) { // found consecutive characters
    result += String.fromCharCode(prevFirstDiffCode); // insert character from prev
    nextFirstDiffCode = nextTerminateCode; // set to end of alphabet
    while ((prevFirstDiffCode = position < prevString.length ? prevString.charCodeAt(position++) : prevTerminateCode) === codeZ) {
      result += 'z'; // insert 'z' to match 'z'
    }
  }

  return result + String.fromCharCode(Math.ceil((prevFirstDiffCode + nextFirstDiffCode) / 2));
};

export function getRootUrl() {
  const { host, protocol } = document.location;
  const rootUrl = `${protocol}//` + host;

  return rootUrl;
}

export function getHost() {
  let { hostname } = window.location;

  let host = hostname;
  if (hostname === "localhost") {
    host += ":8190";
  }

  return host;
}

export const getUrlWithCulture = (path) => {
  const cultureName = cultureNames[i18n.language];
  if (!cultureName) {
    return path;
  }
  try {
    const pathname = path.match(/[^?]+/)[0];
    if (!pathname) {
      return path;
    }
    const query = path.matchAll(/[\?&](?<param>[^&]+=[^&]+)/g);
    const params = Array.from(query).map(m => m.groups.param);
    const cultureParam = params.find(p => p.startsWith('culture='));
    if (!cultureParam) {
      params.push(`culture=${cultureName}`);
    }
    const paramString = params.join('&');
    return `${pathname}?${paramString}`;
  } catch (error) {
    return path;
  }
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const scrollContentToTop = () => {
  var content = document.querySelector('.content');
  if (content) {
    content.scrollTop = 0;
  }
};

export const getIllegalCharactersRegexp = (IllegalCharacters) => {
  return new RegExp(`[${IllegalCharacters.map(c => `\\${c}`).join('')}]`, 'g');
};

export const sortByLocalStrings = (array, fieldName) => {
  return array?.slice().sort((a, b) => {
    let item1 = a[fieldName] ?? '';
    let item2 = b[fieldName] ?? '';
    return item1.localeCompare(item2);
  });
};

export const sortByCalculatedValues = (array, predicate) => {
  return array?.slice().sort((a, b) => {
    let item1 = predicate(a) ?? '';
    let item2 = predicate(b) ?? '';
    return item1.localeCompare(item2);
  });
};

export const hasFlag = (value, flag) => (value & flag) === flag;

export const formatNumber = (value, fractionDigits = 2) => {
  const numberFormatOptions = {
    maximumFractionDigits: fractionDigits
  };
  return Intl.NumberFormat(i18n.language, numberFormatOptions).format(value);
};

export const formatNumberAsCurrency = (value, currencyCode = 'CZK') => {
  try {
    const currencyFormatOptions = {
      style: 'currency',
      currency: currencyCode,
    };

    const result = Intl.NumberFormat(i18n.language, currencyFormatOptions).format(value);
    return result;
  } catch (error) {
    console.error(`Formatting number as currency error. ${error}`);
    return value;
  }
};

export const formatSizeUnits = (bytes) => {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " b";
  } else if (bytes === 1) {
    bytes = bytes + " b";
  } else {
    bytes = "0 b";
  }

  return bytes;
};

export const fileMimeTypeMapping = (fileName) => {
  let tFileName = fileName.trim();
  if (tFileName.trim()[0] === '"' && tFileName[tFileName.length - 1] === '"') {
    tFileName = tFileName.substring(1, tFileName.length - 1);
  }
  const parts = tFileName.split('.');
  const suffix = parts.pop();
  switch (suffix.toLowerCase()) {
    case "doc": return "application/msword";
    case "docx": return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "xls": return "application/vnd.ms-excel";
    case "xlsx": return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "ppt": return "application/vnd.ms-powerpoint";
    case "pps": return "application/vnd.ms-powerpoint";
    case "pptx": return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "pdf": return "application/pdf";
    case "jpeg": return "image/jpeg";
    case "jpg": return "image/jpeg";
    case "png": return "image/png";
    case "gif": return "image/gif";
    case "bmp": return "image/bmp";
    default: return "";
  }
};

export const generateId = () => Number(String(Math.random()).slice(3, 9));
